import React from "react";
import {
  Chart,
  ChartTitle,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
  ChartSeries,
  ChartSeriesItem,
  TooltipContext,
} from "@progress/kendo-react-charts";
import { GetDailyDetectionDTO } from "../data/GetDailyDetectionDTO";

export const DailyChart = (props: { dailyData: GetDailyDetectionDTO[] }) => {
  const transformedData = props.dailyData.map((item) => {
    const dateObj = new Date(item.date); // Преобразуем item.date в объект Date
    return {
      ...item,
      date: dateObj, // Сохраняем объект Date для дальнейшего использования
    };
  });

  // Группируем данные по `inspectionId`
  const groupedData = transformedData.reduce((acc: { [key: number]: GetDailyDetectionDTO[] }, item) => {
    if (!acc[item.inspectionId]) {
      acc[item.inspectionId] = [];
    }
    acc[item.inspectionId].push(item);
    return acc;
  }, {});

  const renderTooltip = (context: TooltipContext) => {
    const dateObj = context.point?.dataItem?.date;
    const time = dateObj ? dateObj.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit', hour12: false }) : "";
    const detectLevel = context.point?.dataItem?.detectLevel ?? "";
  
    return (
      <span>
        <b>Time: {time}</b>
        <br />
        Detect Level: {detectLevel}
      </span>
    );
  };

  return (
    <div>
      <Chart>
        <ChartTitle text="Daily Detection Levels by Inspection" />
        <ChartTooltip render={renderTooltip} />
        
        {/* Настраиваем X-Axis с кастомными категориями */}
        <ChartXAxis>
          <ChartXAxisItem
            title={{ text: "Time" }}
            categories={transformedData.map((item) =>
              item.date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
            )}
          />
        </ChartXAxis>

        <ChartYAxis>
          <ChartYAxisItem title={{ text: "Detect Level" }} />
        </ChartYAxis>

        <ChartSeries>
          {Object.entries(groupedData).map(([inspectionId, data]) => (
            <ChartSeriesItem
              key={inspectionId}
              type="scatter"
              data={data}
              name={`Inspection ${inspectionId}`}
              xField="date"
              yField="detectLevel"
              markers={{ visible: true }}
              tooltip={{ visible: true }}
            />
          ))}
        </ChartSeries>
      </Chart>
    </div>
  );
};

export default DailyChart;
