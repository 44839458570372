import { useRef, useState } from "react";
import {
  MultiViewCalendar,
  MultiViewCalendarProps,
} from "@progress/kendo-react-dateinputs";
import React from "react";
import {
  getDetectionsByDay,
  getDetectionsByMonth,
  getDetectionsByYear,
} from "../services/get-data";
import { GetDailyDetectionDTO } from "../data/GetDailyDetectionDTO";
import { GetMonthlyDetectionDTO } from "../data/GetMonthlyDetectionDTO";
import { DailyChart } from "./DailyChart";
import { MonthlyChart } from "./MonthlyChart";
import { YearlyChart } from "./YearlyChart";
import { GetYearlyDetectionDTO } from "../data/GetYearlyDetectionDTO";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import '../../src/style.css'

const ChartPage = () => {
  const [timeRange, setTimeRange] = useState<"daily" | "monthly" | "yearly">(
    "daily"
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [datePickerProps, setDatePickerProps] =
    useState<MultiViewCalendarProps>({
      views: 1,
      bottomView: "month",
    });
  const navigate = useNavigate();
  const [dailyData, setDailyData] = useState<GetDailyDetectionDTO[] | null>(
    null
  );
  const [monthlyData, setMonthlyData] = useState<
    GetMonthlyDetectionDTO[] | null
  >(null);
  const [yearlyData, setYearlyData] = useState<GetYearlyDetectionDTO[] | null>(
    null
  );

  const goToGrid = () => {
    navigate("/");
  };

  const handleChange = async (event: any) => {
    const newDate = event.value as Date;
    if (timeRange === "daily") {
      setSelectedDate(newDate);
      try {
        const data = await getDetectionsByDay(newDate.toLocaleDateString());
        setDailyData(data);
        setMonthlyData(null);
        setYearlyData(null);
      } catch (error) {
        console.error("Failed to fetch daily data:", error);
        setDailyData(null);
        setMonthlyData(null);
        setYearlyData(null);
      }
    } else if (timeRange === "monthly") {
      const monthDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
      setSelectedDate(monthDate);
      try {
        const data = await getDetectionsByMonth(monthDate.toLocaleDateString());
        setMonthlyData(data);
        setYearlyData(null);
        setDailyData(null);
      } catch (error) {
        console.error("Failed to fetch daily data:", error);
        setDailyData(null);
        setYearlyData(null);
        setMonthlyData(null);
      }
    } else if (timeRange === "yearly") {
      const yearDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
      setSelectedDate(yearDate);
      try {
        const data = await getDetectionsByYear(yearDate.toLocaleDateString());
        setMonthlyData(null);
        setYearlyData(data);
        setDailyData(null);
      } catch (error) {
        console.error("Failed to fetch daily data:", error);
        setDailyData(null);
        setYearlyData(null);
        setMonthlyData(null);
      }
    }
  };

  const handleTimeRangeChange = (event: any) => {
    setTimeRange(event.target.value);

    if (event.target.value === "daily") {
      setDatePickerProps({
        views: 1,
        bottomView: "month",
      });
    } else if (event.target.value === "monthly") {
      setDatePickerProps({
        views: 1,
        bottomView: "year",
      });
    } else if (event.target.value === "yearly") {
      setDatePickerProps({
        views: 1,
        bottomView: "decade",
      });
    }
    setSelectedDate(null);
    setDailyData(null);
  };

  return (
    <div style={{ padding: "20px", display: "flex" }}>
      <div
        style={{
          marginRight: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <LeftOutlined
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={goToGrid}
          />
          <h2>Chart</h2>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label>
            <input
              type="radio"
              value="daily"
              checked={timeRange === "daily"}
              onChange={handleTimeRangeChange}
            />
            Daily
          </label>
          <label style={{ marginLeft: "10px" }}>
            <input
              type="radio"
              value="monthly"
              checked={timeRange === "monthly"}
              onChange={handleTimeRangeChange}
            />
            Monthly
          </label>
          <label style={{ marginLeft: "10px" }}>
            <input
              type="radio"
              value="yearly"
              checked={timeRange === "yearly"}
              onChange={handleTimeRangeChange}
            />
            Yearly
          </label>
        </div>
        <MultiViewCalendar
          value={selectedDate}
          onChange={handleChange}
          {...datePickerProps}
          className="custom-calendar"
        />
        {selectedDate && (
          <p>
            Selected {timeRange}:{" "}
            {selectedDate?.toLocaleDateString(undefined, {
              year: "numeric",
              month: timeRange === "yearly" ? undefined : "long",
              day: timeRange === "daily" ? "numeric" : undefined,
            })}
          </p>
        )}
      </div>

      <div style={{ flexGrow: 1 }}>
        {(dailyData && <DailyChart dailyData={dailyData} />) ||
          (monthlyData && <MonthlyChart selectedDate={selectedDate} monthlyData={monthlyData} />) ||
          (yearlyData && <YearlyChart yearlyData={yearlyData} />)}
      </div>
    </div>
  );
};

export default ChartPage;
