import * as React from "react";
import {
  GridColumnMenuFilter,
  GridColumnMenuSort,
  GridColumnMenuItem,
  GridColumnMenuProps,
  GridColumnMenuColumnsList,
  GridColumnMenuItemGroup,
  GridColumnMenuGroup,
} from "@progress/kendo-react-grid";

export const ColumnMenu: React.FC<GridColumnMenuProps> = (props) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} alwaysExpand={true} />
    </div>
  );
};


/*import * as React from "react";
import {
  GridColumnMenuFilter,
  GridColumnMenuSort,
  GridColumnMenuItem,
  GridColumnMenuProps,
  GridColumnMenuColumnsList,
  GridColumnMenuItemGroup,
  GridColumnMenuGroup,
} from "@progress/kendo-react-grid";
import {
  TabStrip,
  TabStripTab,
  TabStripSelectEventArguments,
} from "@progress/kendo-react-layout";
import {
  filterIcon,
  slidersIcon,
  columnsIcon,
  columnFreezeIcon,
  maxWidthIcon,
  displayInlineFlexIcon,
} from "@progress/kendo-svg-icons";
import { TabStripTabTitle } from "./TabStripTabTitle";
import { ColumnMenuContext } from "./ColumnMenuContext";
import { Button } from "@progress/kendo-react-buttons";
import { InspectionDTO } from "../data/InspectionDTO";
import { DetectionDTO } from "../data/DetectionDTO";

interface CustomColumnProps extends GridColumnMenuProps {
    columnsToSelect: { field: string; title: string; show: boolean }[];
    handleColumnClick: (el: string) => void;
}

export const ColumnMenu: React.FC<CustomColumnProps> = (props) => {
  const { onAutoSize, onColumnsChange, columnsState, columns } =
    React.useContext(ColumnMenuContext);
  const { onCloseMenu = () => {} } = props;
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e: TabStripSelectEventArguments) =>
    setSelected(e.selected);

  const onColumnLock = React.useCallback(() => {
    const index = columnsState.findIndex((c) => c.id === props.column?.id);
    if (index !== -1) {
      const nextColumns = columnsState.slice();
      nextColumns.splice(index, 1, {
        ...columnsState[index],
        locked: !columnsState[index].locked,
      });
      onColumnsChange(nextColumns);
      onCloseMenu();
    }
  }, [onColumnsChange, columnsState, props.column, onCloseMenu]);


  return (
    <TabStrip selected={selected} onSelect={handleSelect} animation={false}>
      <TabStripTab
        title={
          <TabStripTabTitle
            title="Filter"
            iconName="filter"
            svgIcon={filterIcon}
          />
        }
      >
        <GridColumnMenuFilter {...props} alwaysExpand={true} />
      </TabStripTab>

      <TabStripTab
        title={
          <TabStripTabTitle
            title="Columns"
            iconName="columns"
            svgIcon={columnsIcon}
          />
        }
      >
        
      </TabStripTab>
    </TabStrip>
  );
*/


