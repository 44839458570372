import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import {
  DataResult,
  orderBy,
  process,
  SortDescriptor,
  State,
} from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFileExcel,
  faColumns,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { DetectorDTO } from "../data/DetectorDTO";
import {
  getAllDetectors,
  deleteDetector,
  addDetector,
} from "../services/get-data";
import ColumnSelectPopup from "./Popup";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";

interface ColumnSetting {
  field: string;
  title: string;
  show: boolean;
}

const initialSort: Array<SortDescriptor> = [{ field: "name", dir: "asc" }];

const DetectorsGrid: React.FC = () => {
  const [detectors, setDetectors] = useState<DetectorDTO[]>([]);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [sort, setSort] = useState(initialSort);
  const [result, setResult] = useState<DataResult>();
  const [dataState, setDataState] = useState<State>({ take: 10, skip: 0 });
  const [detectorColumns, setDetectorColumns] = useState<ColumnSetting[]>([
    { field: "type", title: "Type", show: true },
    { field: "name", title: "Name", show: true },
    { field: "dateTimeFormat", title: "Date Time Format", show: false },
    { field: "culture", title: "Culture", show: false },
    { field: "datasource", title: "Datasource", show: false },
    { field: "isEnabled", title: "Enabled", show: true },
  ]);
  const [showPopup, setShowPopup] = useState(false);
  const [isAddDetectorModalOpen, setIsAddDetectorModalOpen] = useState(false);
  const [newDetector, setNewDetector] = useState({
    type: "",
    name: "",
    dateTimeFormat: "",
    culture: "",
    datasource: "",
    isEnabled: true,
    detectorName: "",
  });
  const _exporter = useRef<ExcelExport | null>(null);
  const columnButtonContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchDetectors = async () => {
      const data = await getAllDetectors();
      setDetectors(data);
      const updatedResult = createDataState(dataState, data, sort);
      setResult(updatedResult.result);
    };
    fetchDetectors();
  }, [dataState, sort]);

  const createDataState = (
    dataState: State,
    detectorsDto: DetectorDTO[],
    sort?: Array<SortDescriptor>
  ) => {
    const processedData = sort ? orderBy(detectorsDto, sort) : detectorsDto;
    return {
      result: process(processedData, dataState),
      dataState,
    };
  };

  const handleDeleteDetector = async (detectorId: number) => {
    if (!window.confirm("Are you sure you want to delete this detector?")) {
      return;
    }
    try {
      await deleteDetector(detectorId);

      // Убираем удаленный детектор из локального состояния
      const updatedDetectors = detectors.filter(
        (detector) => detector.id !== detectorId
      );
      setDetectors(updatedDetectors);

      // Обновляем отображение таблицы
      const updatedResult = createDataState(dataState, updatedDetectors, sort);
      setResult(updatedResult.result);

      alert(`Detector with ID ${detectorId} deleted successfully.`);
    } catch (error) {
      console.error("Failed to delete detector:", error);
      alert("Failed to delete the detector. Please try again.");
    }
  };

  const handleAddDetector = async () => {
    try {
      // Отправляем новый детектор на сервер
      const addedDetector = await addDetector({
        type: newDetector.type,
        name: newDetector.name,
        dateTimeFormat: newDetector.dateTimeFormat,
        culture: newDetector.culture,
        datasource: newDetector.datasource,
        isEnabled: newDetector.isEnabled,
      });

      // Добавляем новый детектор в локальный список
      const updatedDetectors = [...detectors, addedDetector];
      setDetectors(updatedDetectors);

      // Обновляем отображение таблицы
      const updatedResult = createDataState(dataState, updatedDetectors, sort);
      setResult(updatedResult.result);

      // Закрываем модальное окно и сбрасываем форму
      setIsAddDetectorModalOpen(false);
      setNewDetector({
        type: "",
        name: "",
        dateTimeFormat: "",
        culture: "",
        datasource: "",
        isEnabled: true,
        detectorName: " ",
      });
    } catch (error) {
      console.error("Failed to add detector:", error);
      alert("Failed to add the detector. Please try again.");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          padding: "10px 0",
        }}
      >
        <div ref={columnButtonContainerRef}>
          <button onClick={() => setShowPopup(!showPopup)} className="button">
            <FontAwesomeIcon icon={faColumns} style={{ marginRight: "5px" }} />
            Select Columns
          </button>
        </div>
        <button
          onClick={() => setIsAddDetectorModalOpen(true)}
          className="button"
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
          Add Detector
        </button>
      </div>

      <ColumnSelectPopup
        columnsToSelect={detectorColumns}
        handleColumnClick={(field: string) => {
          setDetectorColumns((prev) =>
            prev.map((col) =>
              col.field === field ? { ...col, show: !col.show } : col
            )
          );
        }}
        show={showPopup}
        setShow={setShowPopup}
        anchorRef={columnButtonContainerRef}
      />

      <Grid
        className="custom-pager"
        style={{
          flex: 1,
          overflowY: "auto",
          minHeight: 0,
          width: "100%",
          height: "100%",
        }}
        data={result}
        skip={dataState.skip}
        take={dataState.take}
        scrollable="scrollable"
        sortable
        sort={sort}
        pageable={{ pageSizes: [5, 10, 20, 50, 100] }}
      >
        {detectorColumns
          .filter((col) => col.show)
          .map((col) => (
            <GridColumn key={col.field} field={col.field} title={col.title} />
          ))}
        <GridColumn
          title="Actions"
          width="100px" // Устанавливаем ширину колонки
          cell={(props) => (
            <td style={{ textAlign: "center", padding: "0" }}>
              {" "}
              {/* Убираем внутренние отступы */}
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "4px",
                  backgroundColor: "red",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  width: "80px", // Устанавливаем ширину кнопки
                  display: "inline-block",
                }}
                onClick={() => handleDeleteDetector(props.dataItem.id)}
              >
                Delete
              </button>
            </td>
          )}
        />
      </Grid>

      <Modal
        open={isAddDetectorModalOpen}
        onClose={() => setIsAddDetectorModalOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <h2>Add New Detector</h2>
          <TextField
            label="Type"
            fullWidth
            margin="normal"
            value={newDetector.type}
            onChange={(e) =>
              setNewDetector({ ...newDetector, type: e.target.value })
            }
          />
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={newDetector.name}
            onChange={(e) =>
              setNewDetector({ ...newDetector, name: e.target.value })
            }
          />
          <TextField
            label="Date Time Format"
            fullWidth
            margin="normal"
            value={newDetector.dateTimeFormat}
            onChange={(e) =>
              setNewDetector({ ...newDetector, dateTimeFormat: e.target.value })
            }
          />
          <TextField
            label="Culture"
            fullWidth
            margin="normal"
            value={newDetector.culture}
            onChange={(e) =>
              setNewDetector({ ...newDetector, culture: e.target.value })
            }
          />
          <TextField
            label="Datasource"
            fullWidth
            margin="normal"
            value={newDetector.datasource}
            onChange={(e) =>
              setNewDetector({ ...newDetector, datasource: e.target.value })
            }
          />
          <Button
            onClick={handleAddDetector}
            variant="contained"
            color="primary"
            style={{ marginTop: "15px" }}
          >
            Add Detector
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default DetectorsGrid;
