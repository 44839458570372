import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { InspectionDTO } from "../data/InspectionDTO";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { moreVerticalIcon } from "@progress/kendo-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faColumns, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import {
  DataResult,
  orderBy,
  SortDescriptor,
  State,
  process,
} from "@progress/kendo-data-query";
import { DetectionDTO } from "../data/DetectionDTO";
import { ColumnMenu } from "./ColumnMenu";
import { Popup } from "@progress/kendo-react-popup";
import ColumnSelectPopup from "./Popup";

interface DetectionGridProps {
  selectedInspection: InspectionDTO | null;
  columns: { field: string; title: string; show: boolean }[];
  handleFieldClick: (el: string) => void;
}

const initialSort: Array<SortDescriptor> = [
  { field: "ProductName", dir: "asc" },
];

const DetectionGrid: React.FC<DetectionGridProps> = (props) => {
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [dataState, setDataState] = useState<State>({ take: 10, skip: 0 });
  const [sort, setSort] = useState(initialSort);
  const [result, setResult] = useState<DataResult>();
  const [showPopup, setShowPopup] = useState(false);
  const _exporter = useRef<ExcelExport | null>(null);
  const columnButtonContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.selectedInspection && props.selectedInspection.detections) {
      const updatedResult = createDataState(
        dataState,
        props.selectedInspection.detections,
        sort
      );
      setResult(updatedResult.result);
    }
  }, [dataState, props.selectedInspection?.detections, sort]);

  const handlePageChange = (event: GridPageChangeEvent) => {
    const newPage = { skip: event.page.skip, take: event.page.take };
    setPage(newPage);
    setDataState({ ...dataState, ...newPage });
  };

  const dataStateValueChange = (
    dataState: State,
    sort: Array<SortDescriptor>
  ) => {
    if (!props.selectedInspection?.detections) return;
    const updatedState = createDataState(
      dataState,
      props.selectedInspection.detections as DetectionDTO[],
      sort
    );
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    const updatedState = createDataState(
      event.dataState,
      (props.selectedInspection as InspectionDTO).detections as DetectionDTO[],
      event.dataState.sort
    );
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const createDataState = (
    dataState: State,
    detectionsDto: DetectionDTO[],
    sort?: Array<SortDescriptor>
  ) => {
    const processedData = sort ? orderBy(detectionsDto, sort) : detectionsDto;
    return {
      result: process(processedData, dataState),
      dataState,
    };
  };

  const exportToExcel = () => {
    if (_exporter.current && props.selectedInspection) {
      const visibleColumns = props.columns.filter((col) => col.show);
      const filteredData = props.selectedInspection.detections.map((item) => {
        const filteredItem: any = {};
        visibleColumns.forEach((col) => {
          filteredItem[col.field] = (item as any)[col.field];
        });
        return filteredItem;
      });

      _exporter.current.save(filteredData);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {props.selectedInspection && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              padding: "10px 0",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <button onClick={exportToExcel} className="button">
                <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: "5px" }} />
                Export to Excel
              </button>
            </div>
            <div style={{ marginLeft: "auto" }} ref={columnButtonContainerRef}>
              <button onClick={() => setShowPopup(!showPopup)} className="button">
                <FontAwesomeIcon icon={faColumns} style={{ marginRight: "5px" }} />
              </button>
            </div>
          </div>
  
          <ColumnSelectPopup
            columnsToSelect={props.columns}
            handleColumnClick={props.handleFieldClick}
            show={showPopup}
            setShow={setShowPopup}
            anchorRef={columnButtonContainerRef}
          />
  
          <ExcelExport ref={_exporter} fileName="Detections.xlsx">
            <Grid
              key={dataState.skip}
              className="custom-pager"
              resizable
              style={{
                flex: 1,
                overflowY: "auto",
                minHeight: 0,
                width: "100%",
              }}
              data={result}
              skip={dataState.skip}
              take={dataState.take}
              scrollable="scrollable"
              sortable
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                const updatedDataState = { ...dataState, sort: e.sort };
                setSort(e.sort);
                dataStateValueChange(updatedDataState, e.sort);
              }}
              onDataStateChange={dataStateChange}
              total={props.selectedInspection.detections.length}
              pageable={{ pageSizes: [5, 10, 20, 50, 100] }}
              onPageChange={handlePageChange}
              columnMenuIcon={moreVerticalIcon}
            >
              {props.columns
                .filter((col) => col.show)
                .map((col) => (
                  <GridColumn
                    key={col.field}
                    field={col.field}
                    title={col.title}
                    columnMenu={(menuProps) => <ColumnMenu {...menuProps} />}
                  />
                ))}
            </Grid>
          </ExcelExport>
        </>
      )}
    </div>
  );
  
};
const buttonStyle = {
  display: "flex",
  alignItems: "center",
  padding: "8px 15px", // Увеличенные размеры кнопки
  backgroundColor: "#ffffff",
  color: "#000000",
  border: "1px solid #cccccc", // Светло-серый бордер
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "14px",
  marginTop: "5px",
  marginRight: "5px",
};
export default DetectionGrid;
