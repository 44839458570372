import React, { useState, useEffect, useRef } from 'react';
import { Popup } from '@progress/kendo-react-popup';

interface Column {
  field: string;
  title: string;
  show: boolean;
}

interface ColumnSelectPopupProps {
  columnsToSelect: Column[];
  handleColumnClick: (field: string) => void;
  show: boolean;
  setShow: (show: boolean) => void;
  anchorRef: React.RefObject<HTMLDivElement>;
}

const ColumnSelectPopup: React.FC<ColumnSelectPopupProps> = (props) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        props.anchorRef.current &&
        !props.anchorRef.current.contains(event.target as Node)
      ) {
        props.setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [props]);

  return (
    <Popup anchor={props.anchorRef.current} show={props.show} popupClass="popup-content">
      <div ref={popupRef} style={{ padding: '10px', background: 'white', border: '1px solid #ccc', borderRadius: '4px' }}>
        <label>Select Columns:</label>
        {props.columnsToSelect.map((col) => (
          <label key={col.field} style={{ display: 'block' }}>
            <input
              type="checkbox"
              checked={col.show}
              onChange={() => props.handleColumnClick(col.field)}
            />
            {col.title}
          </label>
        ))}
      </div>
    </Popup>
  );
};

export default ColumnSelectPopup;
