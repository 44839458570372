import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { InspectionDTO } from "../data/InspectionDTO";
import {
  DataResult,
  orderBy,
  process,
  SortDescriptor,
  State,
} from "@progress/kendo-data-query";
import { moreVerticalIcon } from "@progress/kendo-svg-icons";
import { ColumnMenu } from "./ColumnMenu";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrint,
  faFileExcel,
  faChartLine,
  faBars,
  faColumns,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ColumnSelectPopup from "./Popup";
import configData from "../services/get-config";

interface InspectionGridProps {
  inspections: InspectionDTO[];
  handleRowClick: (el: InspectionDTO) => void;
  handleFieldClick: (el: string) => void;
  columns: { field: string; title: string; show: boolean }[];
  inspectionsLoaded: boolean;
}

const initialSort: Array<SortDescriptor> = [
  { field: "ProductName", dir: "asc" },
];

const InspectionGrid: React.FC<InspectionGridProps> = (props) => {
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [sort, setSort] = useState(initialSort);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [result, setResult] = useState<DataResult>();
  const [showPopup, setShowPopup] = useState(false);
  const [dataState, setDataState] = useState<State>({ take: 10, skip: 0 });
  const _exporter = useRef<ExcelExport | null>(null);
  const columnButtonContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.inspectionsLoaded) {
      const updatedResult = createDataState(dataState, props.inspections, sort);
      setResult(updatedResult.result);
    }
  }, [dataState, props.inspections, props.inspectionsLoaded, sort]);

  const handlePageChange = (event: GridPageChangeEvent) => {
    const newPage = { skip: event.page.skip, take: event.page.take };
    setPage(newPage);
    setDataState({ ...dataState, ...newPage });
  };

  const print = async () => {
    const url = await configData();
    const response = await fetch(`${url.SERVER_URL}api/PrintDataByInspectionId?inspectionId=${selectedRow}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to generate PDF');
    }
  
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `Inspection_${selectedRow}.pdf`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  
    // Clean up the object URL
    URL.revokeObjectURL(downloadUrl);
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    const updatedState = createDataState(
      event.dataState,
      props.inspections,
      event.dataState.sort
    );
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const dataStateValueChange = (
    dataState: State,
    sort: Array<SortDescriptor>
  ) => {
    const updatedState = createDataState(dataState, props.inspections, sort);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const createDataState = (
    dataState: State,
    inspectionsDto: InspectionDTO[],
    sort?: Array<SortDescriptor>
  ) => {
    const processedData = sort ? orderBy(inspectionsDto, sort) : inspectionsDto;
    return {
      result: process(processedData, dataState),
      dataState,
    };
  };

  const exportToExcel = () => {
    if (_exporter.current) {
      const visibleColumns = props.columns.filter((col) => col.show);
      const filteredData = props.inspections.map((item) => {
        const filteredItem: any = {};
        visibleColumns.forEach((col) => {
          filteredItem[col.field] = item[col.field as keyof InspectionDTO];
        });
        return filteredItem;
      });
      _exporter.current.save(filteredData);
    }
  };

  const goToCharts = () => {
    navigate("/charts");
  };

  const handleRowClick = (event: any) => {
    props.handleRowClick(event.dataItem as InspectionDTO);
    setSelectedRow(event.dataItem.id);
  };

  const rowRender = (trElement: React.ReactElement, rowProps: any) => {
    const isSelected = rowProps.dataItem.id === selectedRow;
    return React.cloneElement(trElement, {
      style: {
        ...trElement.props.style,
        backgroundColor: isSelected ? "rgba(66, 139, 202, 1)" : "",
        color: isSelected ? "white" : "",
      },
    });
  };
  
  const goToDetectors = () => {
    navigate("/detectors");
  };
  
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          padding: "10px 0",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <button onClick={print} className="button">
            <FontAwesomeIcon icon={faPrint} style={{ marginRight: "5px" }} />
            Print
          </button>
          <button onClick={exportToExcel} className="button">
            <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: "5px" }} />
            Export to Excel
          </button>
          <button onClick={goToCharts} className="button">
            <FontAwesomeIcon icon={faChartLine} style={{ marginRight: "5px" }} />
            Charts
          </button>
          {/* New Detectors Button */}
          <button onClick={goToDetectors} className="button">
            <FontAwesomeIcon icon={faBars} style={{ marginRight: "5px" }} />
            Detectors
          </button>
        </div>
        <div style={{ marginLeft: "auto" }} ref={columnButtonContainerRef}>
          <button onClick={() => setShowPopup(!showPopup)} className="button">
            <FontAwesomeIcon icon={faColumns} style={{ marginRight: "5px" }} />
          </button>
        </div>
      </div>
      
      {/* Other components remain unchanged */}
      <ColumnSelectPopup
        columnsToSelect={props.columns}
        handleColumnClick={props.handleFieldClick}
        show={showPopup}
        setShow={setShowPopup}
        anchorRef={columnButtonContainerRef}
      />
  
      <ExcelExport ref={_exporter}>
        <Grid
          key={dataState.skip}
          className="custom-pager"
          resizable
          style={{
            flex: 1,
            overflowY: "auto",
            minHeight: 0,
            width: "100%",
          }}
          data={result}
          skip={dataState.skip}
          take={dataState.take}
          scrollable="scrollable"
          sortable
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            const updatedDataState = { ...dataState, sort: e.sort };
            setSort(e.sort);
            dataStateValueChange(updatedDataState, e.sort);
          }}
          onDataStateChange={dataStateChange}
          total={props.inspections.length}
          pageable={{ pageSizes: [5, 10, 20, 50, 100] }}
          onPageChange={handlePageChange}
          onRowClick={handleRowClick}
          rowRender={rowRender}
          columnMenuIcon={moreVerticalIcon}
        >
          {props.columns
            .filter((col) => col.show)
            .map((col) => (
              <GridColumn
                key={col.field}
                field={col.field}
                title={col.title}
                columnMenu={(menuProps) => <ColumnMenu {...menuProps} />}
              />
            ))}
        </Grid>
      </ExcelExport>
    </div>
  );
  
 
};


export default InspectionGrid;
