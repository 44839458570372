import React from 'react';
import ContentRouter from './components/route/content-route';

const App: React.FC = () => {
  return (
    <div>
        <ContentRouter />
    </div>);
};

export default App;
