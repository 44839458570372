import React, { useEffect, useState, useRef } from "react";
import { InspectionDTO } from "../data/InspectionDTO";
import DetectionGrid from "../components/DetectionGrid";
import InspectionGrid from "../components/InspectionGrid";
import DetectionChart from "../components/DetectionChart";
import {
  getDetectionsByInspectionId,
  getInspections,
} from "../services/get-data";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

interface ColumnSetting {
  field: string;
  title: string;
  show: boolean;
}

const GridComponent: React.FC = () => {
  const [selectedInspection, setSelectedInspection] = useState<InspectionDTO | null>(null);
  const [inspections, setInspections] = useState<InspectionDTO[]>([]);
  const [inspectionsLoaded, setInspectionsLoaded] = useState(false);
  const [detectionData, setDetectionData] = useState<{ dateTime: Date; detectLevel: number }[]>([]);
  const [isChartOpen, setIsChartOpen] = useState(false);
  const [inspectionColumns, setInspectionColumns] = useState<ColumnSetting[]>([
    { field: "number", title: "Number", show: true },
    { field: "productName", title: "Product", show: true },
    { field: "userFirstName", title: "First Name", show: true },
    { field: "userLastName", title: "Last Name", show: true },
    { field: "totalCnt", title: "Total Count", show: true },
    { field: "passCnt", title: "Pass Count", show: true },
    { field: "failCnt", title: "Fail Count", show: true },
    { field: "detectorName", title: "Detector Name", show: true },
    { field: "startDate", title: "Start Date", show: false },
    { field: "endDate", title: "End Date", show: false },
  ]);

  const [detectionColumns, setDetectionColumns] = useState<ColumnSetting[]>([
    { field: "id", title: "Detection ID", show: true },
    { field: "dateTime", title: "DateTime", show: true },
    { field: "channel", title: "Channel", show: true },
    { field: "detectLevel", title: "Detect Level", show: true },
    { field: "detectValue", title: "Detect Value", show: true },
    { field: "isTest", title: "Is Test", show: true },
  ]);

  const [leftWidth, setLeftWidth] = useState(50);
  const resizerRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isDragging = useRef(false);

  useEffect(() => {
    getInspections().then((data) => {
      setInspections(data);
      setInspectionsLoaded(true);
    });
  }, []);

  const handleRowClick = (inspection: InspectionDTO) => {
    setSelectedInspection(inspection);

    getDetectionsByInspectionId(inspection.id)
      .then((data) => setDetectionData(data))
      .catch((error) => console.error("Failed to fetch detection data:", error));
  };

  const handleInspectionColumnToggle = (field: string) => {
    setInspectionColumns((columns) =>
      columns.map((col) => (col.field === field ? { ...col, show: !col.show } : col))
    );
  };

  const handleDetectionColumnToggle = (field: string) => {
    setDetectionColumns((columns) =>
      columns.map((col) => (col.field === field ? { ...col, show: !col.show } : col))
    );
  };

  const onMouseDown = () => {
    isDragging.current = true;
    document.body.style.userSelect = "none";
  };

  const onMouseMove = (e: MouseEvent) => {
    if (isDragging.current && containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const newLeftWidth = (e.clientX / containerWidth) * 100;
      if (newLeftWidth > 10 && newLeftWidth < 90) {
        setLeftWidth(newLeftWidth);
      }
    }
  };

  const onMouseUp = () => {
    isDragging.current = false;
    document.body.style.userSelect = "auto";
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => onMouseMove(e);
    const handleMouseUp = () => onMouseUp();

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const openChart = () => {
    setIsChartOpen(true);
  };

  const closeChart = () => {
    setIsChartOpen(false);
  };

  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", flex: 1, overflow: "hidden" }}>
        <div
          style={{
            width: selectedInspection ? `${leftWidth}%` : "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            paddingRight: "5px",
            boxSizing: "border-box",
            transition: "width 0.2s ease",
          }}
        >
          <InspectionGrid
            inspections={inspections}
            handleRowClick={handleRowClick}
            handleFieldClick={handleInspectionColumnToggle}
            columns={inspectionColumns}
            inspectionsLoaded={inspectionsLoaded}
          />
        </div>

        {selectedInspection && (
          <>
            <div
              ref={resizerRef}
              onMouseDown={onMouseDown}
              style={{
                width: "10px",
                cursor: "col-resize",
                backgroundColor: "#ccc",
                height: "100%",
                zIndex: 1,
              }}
            />

            <div
              style={{
                width: `${100 - leftWidth}%`,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                paddingLeft: "5px",
                boxSizing: "border-box",
                transition: "width 0.2s ease",
              }}
            >
              <DetectionGrid
                selectedInspection={selectedInspection}
                columns={detectionColumns}
                handleFieldClick={handleDetectionColumnToggle}
              />
            </div>
          </>
        )}
      </div>

      <div style={{ marginTop: "auto" }}>
        <Button
          onClick={openChart}
          variant="contained"
          color="primary"
          style={{
            width: "100%",
            height: "50px",
            borderRadius: "0",
          }}
        >
          SHOW CHART
        </Button>
      </div>

      <Modal open={isChartOpen} onClose={closeChart} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: '80%', height: '80%', backgroundColor: 'white', padding: '20px', borderRadius: '8px', display: 'flex', flexDirection: 'column' }}>
          <h2 style={{ textAlign: "center" }}>Detection Chart</h2>
          <div style={{ flex: 1, overflow: 'auto' }}>
            <DetectionChart data={detectionData} />
          </div>
          <Button onClick={closeChart} variant="contained" color="secondary" style={{ alignSelf: 'center', marginTop: '20px' }}>
            CLOSE
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default GridComponent;
