import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChartPage from "../Charts";
import GridComponent from "../GridComponent";
import React from "react";
import DetectorsGrid from "../DetectorsGrid";

export default function ContentRouter() {
  const BrowserRoutes = () => {
    return (
      <BrowserRouter>
        <Routes>
          {<Route path="/" element={<GridComponent />} />}
          {<Route path="/charts" element={<ChartPage />} />}
          {<Route path="/detectors" element={<DetectorsGrid />} />}
        </Routes>
      </BrowserRouter>
    );
  };

  return <BrowserRoutes />;
}
