import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
  } from "@progress/kendo-react-charts";
  import React from "react";
import { GetYearlyDetectionDTO } from "../data/GetYearlyDetectionDTO";
  
  export const YearlyChart = (props: {yearlyData: GetYearlyDetectionDTO[]}) => {
    return (
      <div>
        <Chart>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={[1,2,3,4,5,6,7,8,9,10,11,12]}
            />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="column"
              tooltip={{ visible: true }}
              data={props.yearlyData.map((item) => item.passCount)}
              name="Passed"
              color="#8cbd8c"
              stack={true}
            />
            <ChartSeriesItem
              type="column"
              tooltip={{ visible: true }}
              data={props.yearlyData.map((item) => item.failCount)}
              name="Failed"
              color="#d9756c"
              stack={true}
            />
          </ChartSeries>
        </Chart>
      </div>
    );
  };
  