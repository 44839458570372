import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { GetMonthlyDetectionDTO } from "../data/GetMonthlyDetectionDTO";


const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
};

export const MonthlyChart = (props: { monthlyData: GetMonthlyDetectionDTO[], selectedDate: Date | null }) => {
  const { selectedDate, monthlyData } = props;
  return (
    <div>
      {props.selectedDate && <Chart>
        <ChartValueAxis>
          <ChartValueAxisItem min={0} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={Array.from({ length: getDaysInMonth((selectedDate as Date).getFullYear(), (selectedDate as Date).getMonth() + 1) }, (_, i) => i + 1)} />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem
            type="column"
            tooltip={{ visible: true }}
            data={monthlyData.map((item) => item.passCount)}
            name="Passed"
            color="#8cbd8c"
            stack={true}
          />
          <ChartSeriesItem
            type="column"
            tooltip={{ visible: true }}
            data={monthlyData.map((item) => item.failCount)}
            name="Failed"
            color="#d9756c"
            stack={true}
          />
        </ChartSeries>
      </Chart>}
    </div>
  );
};
