import React from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
  ChartTooltip,
  TooltipContext,
} from "@progress/kendo-react-charts";
import "@progress/kendo-theme-default/dist/all.css";

interface DetectionChartProps {
  data: { dateTime: Date; detectLevel: number }[];
}

const DetectionChart: React.FC<DetectionChartProps> = ({ data }) => {
  // Transform data and keep date object
  const transformedData = data.map((item) => ({
    ...item,
    date: new Date(item.dateTime),
  }));

  // Calculate min and max times and add an hour to each
  const minDate = new Date(Math.min(...transformedData.map((item) => item.date.getTime())));
  const maxDate = new Date(Math.max(...transformedData.map((item) => item.date.getTime())));
  minDate.setHours(minDate.getHours() - 1);
  maxDate.setHours(maxDate.getHours() + 1);

  // Tooltip function
  const renderTooltip = (context: TooltipContext) => {
    const dateObj = context.point?.dataItem?.date;
    const time = dateObj ? dateObj.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : "";
    const detectLevel = context.point?.dataItem?.detectLevel ?? "";

    return (
      <span>
        <b>Time: {time}</b>
        <br />
        Detect Level: {detectLevel}
      </span>
    );
  };

  return (
    <div style={{ height: "300px", width: "100%", paddingTop: "10px" }}>
      <Chart>
        <ChartTooltip render={renderTooltip} />

        {/* Configure X-Axis with time range and labels */}
        <ChartXAxis>
          <ChartXAxisItem
            title={{ text: "Time" }}
            min={minDate}
            max={maxDate}
            baseUnit="hours"
            labels={{ format: "{0:HH:mm}" }}
          />
        </ChartXAxis>

        <ChartYAxis>
          <ChartYAxisItem title={{ text: "Detect Level" }} />
        </ChartYAxis>

        <ChartSeries>
          <ChartSeriesItem
            type="scatter"
            data={transformedData}
            xField="date"
            yField="detectLevel"
            markers={{ visible: true }}
            tooltip={{ visible: true }}
          />
        </ChartSeries>
      </Chart>
    </div>
  );
};

export default DetectionChart;
