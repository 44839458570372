import { InspectionDTO } from '../data/InspectionDTO';
import {GetDailyDetectionDTO} from '../data/GetDailyDetectionDTO'
import { GetMonthlyDetectionDTO } from '../data/GetMonthlyDetectionDTO';
import { GetYearlyDetectionDTO } from '../data/GetYearlyDetectionDTO';
import configData from './get-config';
import { DetectorDTO } from '../data/DetectorDTO';

export const getInspections = async () => {
  var url = await configData();
  const response = await fetch(`${url.SERVER_URL}api/GetInspections`, {
    method: 'GET',
    credentials: 'include',
    headers: {
         'Content-Type': 'application/json'
        }
    });

  if (!response.ok) {
    throw new Error('Failed to fetch inspections');
  }
  
  const data = await response.json();
  return data as InspectionDTO[];
};

export const getAllDetectors = async () => {
  var url = await configData();
  const response = await fetch(`${url.SERVER_URL}api/GetAllDetectors`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch detectors');
  }

  const data = await response.json();
  return data as DetectorDTO[];
};

export const editDetector = async (id: number, detector: DetectorDTO) => {
  var url = await configData();
  const response = await fetch(`${url.SERVER_URL}api/EditDetector/${id}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(detector),
  });

  if (!response.ok) {
    throw new Error('Failed to edit detector');
  }

  return await response.json();
};

export const deleteDetector = async (detectorId: number) => {
  var url = await configData();

  const response = await fetch(
    `${url.SERVER_URL}api/DeleteDetector?detectorId=${detectorId}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }
};


export const addDetector = async (detector: DetectorDTO) => {
  var url = await configData();
  const response = await fetch(`${url.SERVER_URL}api/AddDetector`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(detector),
  });

  if (!response.ok) {
    throw new Error('Failed to add detector');
  }

  return await response.json();
};


export const getDetectionsByInspectionId = async (inspectionId: number) => {
  const url = await configData();
  const response = await fetch(`${url.SERVER_URL}api/GetDetectionsByInspectionId?inspectionId=${inspectionId}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch detections');
  }

  const data = await response.json();
  return data as { dateTime: Date; detectLevel: number }[];
};

export const getDetectionsByDay = async (startDate: string) => {
  var url = await configData();
  const apiDate = startDate.split('T')[0];
  const response = await fetch(`${url.SERVER_URL}api/GetInspectionsByDayDate?startDate=${apiDate}`, 
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch inspections by day');
  }

  const data = await response.json();
  return data as Array<GetDailyDetectionDTO>;
};

export const getDetectionsByMonth = async (startDate: string) => {
  var url = await configData();
  const apiDate = startDate.split('T')[0];
  const response = await fetch(`${url.SERVER_URL}api/GetInspectionsByMonthDate?startDate=${apiDate}`, 
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch inspections by day');
  }

  const data = await response.json();
  return data as Array<GetMonthlyDetectionDTO>;
};

export const getDetectionsByYear = async (startDate: string) => {
  var url = await configData();
  const apiDate = startDate.split('T')[0];
  const response = await fetch(`${url.SERVER_URL}api/GetInspectionsByYearDate?startDate=${apiDate}`, 
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch inspections by day');
  }

  const data = await response.json();
  return data as Array<GetYearlyDetectionDTO>;
};
